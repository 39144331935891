import "./App.css";
import React from "react";
import { Link } from "react-router-dom";
import { useTitle } from "../hooks/UseTitle";

const POHTOGRAPHY_TEXT =
  "Some photos I have taken with my Canon EOS 1100D. Mostly urban wildlife and plants, as they seem to be the most readily available subject on short notice.";
// const GAME_TEXT = "A HTML5 rhythm game I made form scratch in JavaScript as a hobby project. All art was done myself, though the music is 'Run against the universe' by Komiku, which is in the public domain."
// https://commons.wikimedia.org/wiki/File:Komiku_-_07_-_Run_against_the_universe.ogg
// const WEBGL_GAME_TEXT =
//   "A WebGL game made with Unity. Currently partially in development, and very broken.";


// The project rectangle containing an image and text
function Project(props) {
  return (
    <Link className="project-link" to={props.link}>
      <div imageside={props.imageside} className="project">
      <div className="project_img ">
        <img src={require(`../images/${props.imageName}`)} alt={props.alt} />
        </div>
        <div className="project_txt">
          <h3>{props.headline}</h3>
          <p>{props.text}</p>
        </div>
      </div>
    </Link>
  );
}

function Projects() {
  useTitle("Projects");
  return (
    <div>
      <div>
        <h1>Projects</h1>
      </div>
      <div className="full-w-flex-col">
        <Project
          imageside="left"
          link={"photography"}
          alt={"Photography"}
          headline="Photography"
          imageName={"Birds.jpg"}
          text={POHTOGRAPHY_TEXT}
        />
        {/* <Project imageSide="right" 
            link={"rhythm"} 
            alt={"Rhythm Game"} 
            headline="Rhythm Game" 
            imageName={"game_screenshot.png"}
            text={GAME_TEXT}
            /> */}
        {/* <Project
          imageside="right"
          link={"topdown"}
          alt={"WebGL Game"}
          headline="WebGL Game"
          imageName={"topdown_SC.png"}
          text={WEBGL_GAME_TEXT}
        /> */}
      </div>
    </div>
  );
}

export default Projects;
